import styled from 'styled-components';

import {CustomListItem, MenuList} from '../NavigationBarV2/styles'
import BackgroundImage from '/public/images/WellnessPage/awareness.png';
import Scurve from '/public/images/WellnessPage/scurve.svg';
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';
const ExtraBoldFont = 'Rubik-Bold';
const MontserratRegular = 'Montserrat-Regular';
const MontserratSemiBold = 'Montserrat-SemiBold';
const TopPanel = styled.div`
  display:flex;
  flex-direction:row;
  width: 100%;
  height: 100%;
  max-width: 1284px;
  margin-left: auto;
  margin-right: auto;
  > div:first-child{
    flex:1;
    display: flex;
    padding-top: 0px;
    padding-bottom: 33px;
    padding-left: 80px;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 55px;
    padding-right: 70px;
     > p {
        font-style: normal;
        font-size: 18px;
        line-height: 110.5%;
        color: #9FC989;
        letter-spacing: 0px;
        margin-top: 10px;
        margin-bottom: 11px;
        font-family: ${MontserratSemiBold}
     }
    > h1 {
        font-size: 48px;
        margin: 0;
        color: #24395a;
        font-family: Rubik-Medium;
        font-style: normal;
        letter-spacing: 0;
        line-height: 110.5%;
        font-weight: bold;
        word-break: break-word;
        width: 100%;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 25px;
        >img{
          position: absolute;
          left: 0;
          bottom: 10px;
          width: 70px;
          height: 4px;
        }
    }
    >img{
      width: 75px;
      height: 5px;
    }
    >Button{
      margin-left:0;
      background-color: #9FC989;
    }
  }
  > div:last-child{
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    height: 600px;
    width: 650px;
    background-image: url("/images/WellnessPage/banner_bg.png");
    background-size: 100% 100%;
    @media(max-width: 1366px){
      flex: 1;
    }
    > picture {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    img {
        width: auto;
        height: 510px;
        margin: 0 auto;
        position: relative;
        left: -133px;
        top: 45px;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0;
    > div:first-child{
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
     > h1 {
        font-size: 36px;
        margin: 0;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 57px;
        font-weight: 800;
        line-height: 57px;
        word-break: break-word;
        width: 100%;
    } 
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        > picture {
          justify-content: center;
        }
        img {
            width: 80%;
            position: relative;
            top: 0px;
            left: 40px;
        }
    }
  }
  @media (max-width: 500px) {
  >div:first-child {
        width: 100%;
        padding: 30px 10px;
        margin-bottom: 20px;
    > h1 {
      margin: 0;
      color: #404555;
      font-family: ${BoldFont};
      letter-spacing: 0;
      font-weight: 600;
      line-height: 36px;
      word-break: break-word;
      width: 100%;
      padding-left: 20px;
      font-size: 36px;
      margin-bottom: 20px;
    }
    > p {
        font-family: ${MontserratSemiBold};
        font-weight: 800;
        margin-top: 30px;
        margin-bottom: 25px;
        padding-left: 20px;
        font-weight: 600;
        font-size: 16px;
     }
     >img{
      padding-left: 20px;
    }
     >button{
        width: 315px;
        font-size: 18px;
        line-height: 21px
        margin-top: 10px;
        margin: 0 auto;
      } 
    }
    >div:last-child{
      background-size: 0 0;
      padding: 0;
      margin-top: 0;
      >picture{
        width:100%;
        >img{
          width: 375px;
          height: 280px;
          position: relative;
          left: 10px;
          top: 0px;
        }
      }
    }
  }
  @media (min-width: 1600px) {
  
`;
const CoreFeatureSectionV2 = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  //padding-top: 100px;
  padding-bottom:50px;
  >div{
      width:100%;
      justify-content:center;
      text-align: center;
    >p{
      color:#F6797D;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      font-family: ${MontserratSemiBold};
    }   
     >h3{
      font-family: 'Montserrat-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #24395A;
      justify-content: center;
    }
    >h1{
      margin-top:20px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
      text-transform: capitalize;
    }
    >h2{
      margin-top:20px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
      text-transform: capitalize;
    }
  }
  @media(max-width: 600px){
    padding-top: 0;
    padding-bottom: 0px;
    >div{
      text-align: center;
      >p{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      >h1{
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        font-style: normal;
        font-size: normal;
        font-family: 'Rubik-Bold';
        font-weight: 700;
        // margin-top:70px
        padding: 0px 7px;
        margin-bottom: 0px;
      }
    }
  }
`;

const MainResources = styled.div`
  width:100%;
  position:relative;
  float: left;
  margin-bottom: 20px;
  .line{
    position: absolute;
    left:0px;
    >svg{
      fill: ${({color}) => color};
    }
  }
  >h1{
    width: 80%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395A;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
    margin-left: 345px;
  }
  .line{
    right: 548px;
  }
  .scurve{
    float: left;
    width: 100%;
    background-image: url(${Scurve});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: translate(-90px, 90px);
    @media(max-width: 1400px){
      transform: translate(-20px,90px);
    }
  }
  @media(max-width: 500px){
    >h1{
      font-weight: 500;
      font-size: 26px;
      width: 100%;
      margin: 0 0 20px 0;
      padding-left: 20px;
    }
    .line{
      padding-left: 20px;
    }
  }
`;

const Button = styled.button`
  width: ${(props) => props.tryFree ? '190px' : '190px'};
  height:50px;
  background-color: ${(props) => props.tryFree ? '#fff' : '#F6797D' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 20px;
  line-height: 24px;
  font-family: Rubik-Medium;
  font-style: normal;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 500px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
`;

const Button2 = styled.button`
width: ${(props) => props.tryFree ? '200px' : '236px'};
margin-top:20px;
  background-color: ${(props) => props.tryFree ? '#fff' : '#69C2FF' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '14px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-style: normal;
  line-height: 21px;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
`;

const TopPanelContent = styled.div`
  >p{
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    color: #24395a;
    margin: 35px 0 40px 0;
    font-family: ${MontserratRegular};
  }
    @media (max-width: 500px) {
    > p {
        font-family: ${MediumFont};
        font-size: 15px;
        line-height: 26px;
        color: #404555;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 25px;
        padding-left: 20px;
     } 
  }
`;

const MiddlePanel = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    float: left;
    width: 50%;
    flex-direction: column;
    
    img {
        width: 200px;
        float: right;
    }
      
  }
  > div:last-child{
    width: 50%;
    padding: 30px 70px 0 60px;
    float: left;
    > p {
        font-family: ${RegularFont};
        font-size: 20px;
        line-height: 30px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding-left: 19px;
     }
    > h1 {
        font-size: 28px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 33px;
        font-weight: 500;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
    }

  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
        img {
           width: 300px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        padding: 0 62px;
        align-items: center;
    }
  }
  
@media (max-width: 500px) {
    text-align: center;
     > div {
        width: 100%;
        margin-bottom: 20px;
       img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        } 
    }
    > div:last-child{
        width: 100%;
        align-items: center;
        padding: 11px;
        margin: 0 auto;
        display: flex;
        > p {
        font-family: ${RegularFont};
        font-size: 15px;
        line-height: 15px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding: 0; 
     }
    > h1 {
        font-size: 20px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 250;
        word-break: break-word;
        width: 100%;
        padding: 0px;
    }
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;
const MiddlePanelSecond = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    float: left;
    width: 50%;
    padding: 30px 23px 0 107px;
    flex-direction: column;
    > p {
        font-family: ${RegularFont};
        font-size: 20px;
        line-height: 30px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding-left: 19px;
     }
    > h1 {
        font-size: 28px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 33px;
        font-weight: 500;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
    } 
  }
  > div:last-child{
    width: 50%;
    float: left;
    img {
        width: 200px;
        float: left;
    }
    
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width: 500px) {
    text-align: center;
     > div {
        width: 100%;
        margin-bottom: 20px;
        padding: 11px;
            > p {
        font-family: ${RegularFont};
        font-size: 15px;
        line-height: 15px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
     }
    > h1 {
        font-size: 20px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 250;
        word-break: break-word;
        width: 100%;
        padding: 0px;
    } 
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const CustomListItemV2 = styled(CustomListItem)`
  border-top: ${({ active }) => active ? '5px solid #F6797D' : '5px solid transparent'};
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 0px;
  margin: 0px 14px 0px 14px;
  >a{
    font-size: 20px;
  }
  &:hover, &:focus, &:active {
    border-top: 5px solid #F6797D;
    border-bottom: none;
    >a{
      font-size: 20px;
    }
  }
  @media (max-width: 1200px) {
    min-width: unset;
    >a{
      font-size: 16px;
    }
  }
`;
const MenuListV2 = styled(MenuList)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  @media (max-width: 1200px) {
    position: unset;
    width: 100%;
    box-shadow: none;
    background-color: none;
    padding: 0 0 10px;
  }
`;

const AwarenessSection = styled.div`
  width:80%;
  height:400px;
  display:flex;
  margin:30px 0;
  background: linear-gradient(180deg, #102B52 0%, #006FBC 100%);
  border-radius: 0px 0px 117px 0px;
  padding: 0 0 0 70px;
  .responsiveAwarenessButton{
    display: none;
  }
  >div:first-child{
    width:30%;
    display:flex;
    align-items:center;
    position: relative;
    left: 50px;
      >img{
        width:270px;
        position: relative;
      }
      >div{
        position: absolute;
        left: 9%;
        @media(max-width: 1400px){
          right: 32%;
        }
        font-family: ${BoldFont}
        font-size: 96px;
        line-height: 114px;
        color: #F6797D;
        >span{
          font-weight: 500;
          font-size: 28px;
          line-height: 43px;
          color: #F6797D;
        }
      }
  }
  >div:nth-child(2){
    width:65%;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    padding-left:70px;
    flex-direction: column;
    >p{
      color:#FFFFFF;
      font-size: 30px;
      line-height: 43px;
      margin-bottom:5px;
      font-family: Rubik-Medium;
      font-style: normal;
    }
    >button{
      width:190px;
      margin-top: 10px;
      margin-left: 0;
    }
    }
    @media (max-width:1000px){
      >div:first-child{
        padding: 0;
        >p{
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
    @media (max-width:700px){
      border-radius: 0;
      width: 100%;
      >div:first-child{
        >p{
          font-size: 15px;
          line-height: 25px;
        }
        >Button{
          font-size: 8px;
        }
      }
      >div:nth-child(2){
        >div{
          right: 14%;
          font-size: 50px;
        }
      }
    }
    @media (max-width:600px){
      padding: 10px;
      flex-direction: column;
      height: auto;
      .responsiveAwarenessButton{
        display: flex;
        justify-content: center;
        margin: 20px;
        >button{
          width: 315px;
        }
      }
      >div:first-child{
        width: 100%;
        height: 50%;
        padding: 10px;
        >p{
          width: 100%;
          font-weight: 500;
          font-size: 20px;
          line-height: 36px;
        }
        >button{
          display: none;
        }
      }
      >div:nth-child(2){
        margin-top: 10px;
        justify-content: center;
        width: 100%;
        height: 50%;
        >img{
          width: 200px;
        }
        >div{
          font-weight: 500;
          font-size: 65px;
          line-height: 77px;
          right: 38%;
        }
      }
    }
    @media (max-width:400px){
      >div:nth-child(2){
        >div{
          >span{
            font-size: 21px;
          }
        }
      }
    }
`;
const TextImagesContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    // display: flex;
    // height: 640px;
    margin-top: 160px;
    // max-width: 1200px;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
    justify-content: space-around;
    background: rgba(105, 194, 255, 0.05);
    // flex-direction: column;
    // justify-content: center;
    @media(max-width: 700px){
      margin-bottom: 10px;
      margin-top: 30px;
    }
    .text-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .top-text{
        font-family: 'Rubik-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 58px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: capitalize;
        padding-top: 75px;
        color: #24395A;
        @media(max-width: 700px){
          padding-top: 25px;
          padding-left: 16px;
          padding-right: 16px;
          font-size: 32px;
          line-height: 40px;
        }
      }
      .lower-text{
        font-family: 'Montserrat-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-top: 25px;
        color: #253A5B;
        @media(max-width: 700px){
          padding-left: 30px;
          padding-right: 30px;
          margin-top: 15px;
        }
      }
      .blue-linee{
        width: 80px;
        height: 6px;
        margin-top: 30px;
        @media(max-width: 700px){
          margin-top: 25px;
        }
      }
    }
    .box-wrapper{
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      width: 100%;
      max-width: 1200px;
      margin: auto;
      margin-top: 50px;
      padding-bottom: 75px;
      @media (max-width: 1050px){
        flex-direction: column;
        align-items: center;
        padding-bottom: 25px;
        margin-top: 20px;
      }
      .first-box{
        width: 350px;
        // height: 300px;
        background: #FFFFFF;
        border: 1px solid #69C2FF;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width: 1050px){
          margin-bottom: 25px;
          width: 315px;
        }
        .box-img{
          margin-top: 40px;
          @media(max-width: 1050px){
            margin-top: 30px;
          }
        }
        .box-first-text{
          font-family: 'Rubik-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          text-transform: capitalize;
          color: #243A5A;
          text-align: center;
          margin-top: 22px;
          @media(max-width: 1050px){
            margin-top: 20px;
          }
        }
        .box-last-text{
          font-family: 'Montserrat-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #253A5B;
          margin-top: 19px;
          margin-bottom: 39px;
          @media(max-width: 1050px){
            margin-top: 15px;
            margin-bottom: 15px;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
`;
const Resources = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top:30px;
  flex-direction:${({flexDirection}) => flexDirection};
  justify-content: space-between;
  >div:first-child{
    .responsive{
      display:none;
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    >div:first-child{
      display: flex;
      align-items: center;
      padding-top: 20px;
      flex-direction: row;
      .title{
        font-size: 30px;
        color: ${({col})=>col};
        font-family: Rubik-Medium;
        font-style: normal;
        line-height: 58px;
        letter-spacing: 0;
        display:inline;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
      } 
      >img{
        width: 60px;
        height: 60px;
      }
    }
    >div{
      width: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      cursor:pointer;
    }
    >div:nth-child(3){
      >div{
        >div:last-child{
          >img{
            width:100%;
          }
        }
      }
    }
  }
  > div:last-child {
    width: 100%;
    align-items:${({align}) => align};
    img {
        float: left;
        width: 500px;
        height: auto;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width:600px){
    padding:0;
    margin-top: 10px;
    >div:first-child{
      padding: 0;
      .normal{
        display:none;
      }
      .responsive{
        display:block;
      }
    }
    >div:last-div{
      display:none;
    }
    .image{
      display:none;
    }
    >div:first-child{
      >div:last-child{
        >div{
          >div{
            .responsive-image{
              >img{
                width:100%;
              }
            }
          }
        }
      }
    }
  }
`;

const TabData = styled.div`
  padding: 150px 100px 80px;
  width:100%;
  display:flex;
  align-items:center;
  flex-wrap: wrap;
  .responsiveWhyButton{
    display:none;
    >button{
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      margin-left: 0;
      width: 315px;
    }
  }
  >div:nth-child(2){
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    img {
        width: 80%;
    }
  }
  > div:last-child{
    float: left;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    img {
      width: 13%;
      margin-left:16px;
      margin-bottom:10px;
    }
    > p:first-child{
        width:100%;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0;
        color:#69C2FF;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratSemiBold};
     }
    > p {
        width:100%;
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        color: #24395a;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratRegular};
        @media(max-width: 500px){
          text-align: justify;
          padding-right: 12px;
        }
     }
    > h1 {
        font-size: 30px;
        margin-bottom: 35px;
        color: #24395a;
        font-family: Rubik-bold;
        font-style: normal;
        letter-spacing: 0;
        line-height: 36px;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        text-transform: capitalize;
        margin-top: 0;
        @media(max-width: 500px){
          text-align: left;
          margin: 0;
        }
    } 
  }
  @media(max-width:1000px){
    >div{
      >h1{
    font-size: 24px;
      }
    }
  }
  @media(max-width:850px){
    flex-direction: column-reverse;
    padding: 80px 50px 80px;
    margin: 0;
    >div:nth-child(2){
      margin-top: 40px;
      >img{
        margin: 0;
        width: 100%;
      }
    }
    >div:last-child{
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-top: 60px;
      >p:first-child{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
      >h1{
        padding: 0;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
      }
      >img{
        margin-left: 0;
        margin-top: 20px;
      }
      >p:last-child{
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        padding: 0;
      }
      >button{
        display: none;
      }
  }
  .responsiveWhyButton{
    display: block;
    margin: 0 auto;
    width: 84%;
  }
}

@media(max-width:500px){
  >div{
    >h1{
      font-size: 13px;
      line-height: 19px;
    }
    >p{
      font-size: 11px;
      line-height: 16px;
    }
    p:last-child{
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      padding: 0;
      text-align: justify;
    }
  }
  >div:last-child{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-top: 60px;
  }
`;

const FooterSection = styled.div`
  background-image: linear-gradient(180deg, #003148 0%, #006681 100%);
  padding: 50px 90px 50px 98px;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    padding: 80px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  p {
    font-size: 35px;
    line-height: 40px;
    color: #fff;
    font-family: ${RegularFont}
    font-weight: 400;
    text-align: center;
    margin: 20px 0px;
  }
  }

  @media (max-width: 500px) {
   text-align: center;
   > div{
      padding: 10px 0px;
    p {
      font-size: 23px;
    }
   }
  }
`;

const WrapperContainer = styled.div`
  width: 100%;
  float: left;
  background-color: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 50px;
  @media(max-width: 500px){
    padding-top: 30px;
  }
`;
const RatingsContainer = styled.div`
  background: #EDF7FF;
  width: 100%;
  position: relative;
  margin: 43px 0;
  margin-bottom:0;
  padding-bottom:0;
  border-radius: 6px;
  text-align: center;
  float:left;
  display: flex;
  align-items: center;
  flex-direction: column;
  >img{
    width: 106px;
    height: 106px;
    border-radius: 50%;
    position: absolute;
    top: -52.8px;
    left: 45%;
  }
  .svgContainer{
    margin: 80px auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    >svg{
      fill: #F8B739;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .ratingDescription{
    font-family: ${MediumFont}
    font-size: 18px
    line-height: 26px
    text-align: Center
    color: #000000;
    padding: 0 50px 30px;
    width: 80%;
    font-family: ${MontserratRegular};
    @media(max-width: 1000px){
      padding: 0;
    }
  }
  .ratingBy{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    tex-align: Center
    margin-top: 0px;
    color: #3a3a3a;
    margin-bottom: 40px;
  }
`;
const ResourcesWrapper = styled.div`
  width: 100%;
  float:left;
  @media(max-width: 800px){
    padding: 0 10px;
  }

  .tab-header{
     width: 100%;
     margin-top: 50px;
    .inner-text {
      width: 100%;
      text-align:center;
      >p{
        margin-top: 20px;
      font-size: 30px;
      line-height: 58px;
      -webkit-letter-spacing: 0.03em;
      -moz-letter-spacing: 0.03em;
      -ms-letter-spacing: 0.03em;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-family: Rubik-Bold;
      }
      >h1{
        font-size: 18px;
        line-height: 26px;
        -webkit-letter-spacing: 0.06em;
        -moz-letter-spacing: 0.06em;
        -ms-letter-spacing: 0.06em;
        letter-spacing: 0.06em;
        text-align: center;
        color: #243A5A;
        font-family: Montserrat-Regular;
  
      }
      @media(max-width: 1000px){
        display:none;
      }
      @media(max-width: 720px){
        display:none;
      }
      @media(max-width: 500px){
        display:none;
      }
    }
    .inner-img {
     width: 100%
     background-color: #EDF7FF;
     >img{
      width: 180px;
      }
    
    }
    
  }
 
`;
const RowWrapper = styled.div`
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  .header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >img{
  width: 35px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  }
  >div{
  width: calc(100% - 70px);
  font-size: 24px;
  font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`} ;
  color: #24395A;
  }
  >h3{
  width: calc(100% - 70px);
  font-size: 24px;
  font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`} ;
  color: #24395A;
  }
  .downArrow{
  width: 20px;
  transform: ${({selected}) => selected && 'rotateX(180deg)'};
  >img{
  width: 14px;
  cursor: pointer;
  }
  }
  }
  .expand{
  padding: 20px 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  font-family: ${MontserratRegular};;
  font-style: normal;
  color: #3A3A3A;
  cursor: pointer;
  }
  @media(max-width:500px){
  display:none;
  }
`;

const WellBeing = styled.div`
  width:100%;
  display:flex;
  height: 281px;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 12px;
  justify-content: center;
  >div{
    width:43%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 281px;
  }
  >div:first-child{
    justify-content:flex-start;
    height: 281px;
    >p{
      left: 258px;
      display: flex;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 40px;
      line-height: 47px;
      color: #FFFFFF;
      margin-bottom: 20px;
      @media(max-width: 500px){
        font-size: 30px;
        line-height: 36px;
      }
  }
}
@media (max-width: 1200px) {
  flex-direction: column;
  text-align: center;
  padding : 0;
  >div:first-child{
    width: 100%;
    justify-content: center;
  }
  >div:last-child{
    width: 100%;
    flex-direction: column;
    margin-bottom: 35px;
    >button:last-child{
      margin-top: 5px;
    }
  }
}
@media(max-width: 500px){
  background-image: url("/images/WellnessPage/Group 602.png");
  border-radius: 0px;
  width: 100%;
  height: 335px;
  >div:first-child{
    margin-top: 35px;
  }
}
`;

const ButtonGetStarted = styled.button`
  width: ${(props) => props.tryFree ? '200px' : '236px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#253A5B' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '14px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-style: normal;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  margin-bottom: 20px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 700px) {
    margin-bottom: 5px;
  }
  @media (max-width: 500px) {
    width: 315px;
    margin-left: 2px;
  }
`;

const ShowLogos = styled.div`
  width:100%;
  display:flex;
  float: left;
  padding: 0 80px 0 80px;
  margin: 30px 0;
  justify-content: space-around;
  flex-wrap: wrap;
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 147px;
      height: 36px;
    }
  }
  >div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 133px;
      height: 63px;
    }
  }
  >div:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 193px;
      height: 48px;
    }
  }
  >div:nth-child(4){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 119px;
      height: 49px;
    }
  }
  >div:nth-child(5){
    display: flex;
    align-items: center;
    justify-content: center;
    width:20%;
    >img{
      width: 125px;
      height: 59px;
    }
  }
  @media(max-width: 1200px){
    padding: 0 10px;
    >div{
      width: auto;
      margin: 20px;
    }
  }
`;

const ButtonLearnMore = styled.button`
width: ${(props) => props.tryFree ? '200px' : '236px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#F6B479' };
  color: #253A5B;
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '14px 10px'};
  border-radius: 3px;
  border: 2px solid #253A5B;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-style: normal;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  margin-bottom: 20px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 500px) {
    width: 315px;
    margin-left: 2px;
  }
`;

const OverFooter = styled.div`
  >div:first-child{
    >p{
      margin: 0 0 10px;
      text-align: center;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      text-transform: capitalize;
      color: #24395a;
    }
  }
  >div{
    >p{
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #3A3A3A;
      font-family: ${MontserratRegular};
    }
  }
  >div:last-child{
    display:flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 50px;
    >Button{
      width: 397px;
      margin-top:0;
      height: 45px;
      box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
    }
    >input{
      width: 397px;   
      padding-left:18px;
      height: 45px;
      border: 1px solid #C4C4C4;
      border-radius: 3px;
      font-size: 20px;
      margin-right: 5px;
      line-height: 23px;
      color: #C4C4C4;
      font-family: ${MediumFont}
    }
  }
  @media (max-width:720px){
    text-align: center;
    >div:nth-child(2){
      padding: 0 50px;
    }
    >div:last-child{
      display: block;
      text-align:center;
      >input{
        width: 80%;
        text-align: center;
      }
      >button{
        width: 80%;
        margin-top: 10px;
      }
    }  
  }
  @media(max-width: 500px){
    >div:nth-child(2){
      >p{
        font-size: 15px;
        line-height: 18px;
      }
    }
    >div:last-child{
      >button:last-child{
        font-size: 17px;
      }
    }
  }
`;

const Divider = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  padding: 100px 0;
  >img{

  }
`;

const ShowLogosDivider = styled.div`
  width:100%;
  margin-bottom: 40px;
  img{
    width:100%;
  }
`;
const PaddingContainer = styled.div`
  width: 100%;
  padding: ${({ padding }) => padding ? '50px 117px 0px 117px' : '0px 117px 0px 117px'};
  float: left;
  @media (min-width: 1400px){
    padding: ${({ padding }) => padding ? '50px 117px 0px 155px' : '0px 117px 50px 155px'};
  }
  @media (max-width: 1100px) {
    padding: 50px 6vw;
  }
  @media (max-width: 500px) {
    padding: 0;
  }
`;

const RatingWrapper = styled.div`
  width:100%;
  padding : 50px 117px 0px 117px;
  @media(max-width:1000px){
    padding: 0;
  }
  @media(max-width: 700px){
    padding-top: 60px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 70px;
  text-align: center;

  >p{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #F6797D;
  }
  >h1{
    font-family: Rubik-Medium;
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #253A5B;
  }
  >img{
    width: 75px;
    height: 5px;
  }
  @media(max-width: 500px){
    display:none;
  }
`;

const RatingsContainers = styled(RatingsContainer)`
    .ratingStars {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 80px 0 10px 0;
    }
    .ratingDescription{
      padding: 0px 50px 5px;
      margin: 13px 13px 0px 13px;
      height: 150px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #3a3a3a;
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: ${MontserratRegular};
    }

    .ratingBy{
      span{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #3a3a3a;
      }
    }
    @media(max-width: 1000px){
      >img{
        left: 43%;
      }
      .ratingDescription{
        font-size: 16px;
      }
      

    @media(max-width: 650px){
      .ratingDescription{
        line-height: 26px;
        text-align: justify;
        margin: 30px 0;
        padding: 20px 0 0;
      }
      >img{
        left: 40%;
      }
    }
    @media(max-width: 500px){
      .ratingDescription{
        font-size: 13px;
      }
      >img{
        left: 36%;
      }
    }
`;

const ResponsiveTestimonialTab = styled.div`
  display:none;
  @media(max-width: 1000px){
    float: left;
    width: 100%;
    display: flex;
    .leftArrow{
      cursor: pointer;  
      width: 30%;
      justify-content: center;
      display: flex;
      >img{
        margin: auto 0;
      }
      @media(max-width: 500px){
        justify-content: flex-start;
        padding-left: 20px;
      }
    }
    >div:nth-child(2){
      width: 40%;
      text-align: center;
      border-top: 5px solid #F6797D;
      padding: 10px;
      >p{
        font-family: ${MontserratRegular};
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #F6797D;
      }
    }
    >.rightArrow{
      cursor: pointer;
      width: 30%;
      justify-content: center;
      display: flex;
      >img{
        margin: auto 0;
      }
      @media(max-width: 500px){
        justify-content: flex-end;
        padding-right: 20px;
      }
    }
  }
`;

const MenuListV2Main = styled(MenuList)`
  display: flex;
  width: 100%;
  @media (max-width: 1200px) {
    position: unset;
    width: 100%;
    box-shadow: none;
    background-color: none;
    padding: 0 0 10px;
  }
`;

const CustomListItemV2Main = styled(CustomListItem)`
  display: flex;
  width: 18%;
  justify-content: center;
  margin: 0;
  border-top: ${({ active }) => active ? '5px solid #F6797D' : '5px solid transparent'};
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 0px;
  margin-right: 40px;
  >a{
    font-size: 20px;
    font-family: Rubik-Medium;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    font-weight: normal;
  }
  &:hover, &:focus, &:active {
    border-top: 5px solid #F6797D;
    border-bottom: none;
    >a{
      font-size: 20px;
    }
  }
  @media (max-width: 1200px) {
    min-width: unset;
    border-left: none;
    >a{
      font-size: 16px;
    }
  }
`;

const RowResponsiveWrapper = styled.div`
  display: block;
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >img{
      width: 35px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;
    }
    >div{
      width: calc(100% - 70px);
      font-size: 24px;
      font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`};
      color: #24385A;
    }
    .downArrow{
      width: 20px;
      transform: ${({selected}) => selected && 'rotateX(180deg)'};
      >img{
        width: 14px;
        cursor: pointer;
      }
    }
  }
  .expand{
    padding: 20px 30px 0 50px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    font-family: ${MontserratRegular};
    cursor: pointer;
  }
  @media(max-width: 500px){
    display: flex;
  }
`;
const CoreWrapperNew = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 150px;
  
  >div:first-child{
    .tab-header{
      @media(max-width: 1000px){
        display: block;
      }
      @media(max-width: 720px){
        display: block;
      }
      @media(max-width: 500px){
        display: block;
      }
      @media(max-width: 360px){
        display: block;
      }
      >h1{
        @media(max-width: 360px){
          font-size: 22px;
          padding:0px 10px;
        }
        @media(max-width: 500px){
          font-size: 25px;
          padding:0px 10px;
        }
        @media(max-width: 720px){
          font-size: 34px;
        }
        @media(max-width: 1000px){
          font-size: 16px;
          padding: 0px 14px;
          margin-top: 24px;
        }

      }
            >h1{
        @media(max-width: 360px){
          font-size: 22px;
          padding:0px 10px;
        }
        @media(max-width: 500px){
          font-size: 25px;
          padding:0px 10px;
        }
        @media(max-width: 720px){
          font-size: 34px;
        }
        @media(max-width: 1000px){
          font-size: 16px;
          padding: 0px 14px;
          margin-top: 24px;
        }

      }
    }
  }


  .responsive-core{
 >div{
  @media(max-width: 1200px){
    display: none;
  }
  @media(max-width: 1100px){
    display: none;
  }
  @media(max-width: 1300px){
    display: none;
  }
  @media(max-width: 5000px){
    display: none;
  }
  @media(max-width: 1000px){
    display: block;
  }
  @media(max-width: 720px){
    display: block;
  }
  @media(max-width: 500px){
    display: block;
    
  }
}
}
  .change {
    padding-left: 0px;
    margin: auto;
    margin-bottom: 50px;
    max-width: 1200px;

    @media(max-width: 1000px){
      display:none;
    }
    @media(max-width: 720px){
      display:none;
    }
    @media(max-width: 500px){
      display:none;
    }
  }
  .tab-header{
    text-align:center;
    margin-bottom: 50px;
    ${'' /* >p{
    margin-top: 20px;
    font-size: 36px;
    line-height: 43px;
    -webkit-letter-spacing: 0.03em;
    -moz-letter-spacing: 0.03em;
    -ms-letter-spacing: 0.03em;
    letter-spacing: 0.03em;
    color: #24395A;
    text-align: center;
    font-family: Rubik-Bold;
    } */}
    >h2{
    margin-top: 20px;
    font-size: 36px;
    line-height: 43px;
    -webkit-letter-spacing: 0.03em;
    -moz-letter-spacing: 0.03em;
    -ms-letter-spacing: 0.03em;
    letter-spacing: 0.03em;
    color: #24395A;
    text-align: center;
    font-family: Rubik-Bold;
    }
    >p{
      font-size: 18px;
      line-height: 26px;
      -webkit-letter-spacing: 0.06em;
      -moz-letter-spacing: 0.06em;
      -ms-letter-spacing: 0.06em;
      letter-spacing: 0.06em;
      text-align: center;
      color: #243A5A;
      font-family: Montserrat-Regular;

    }
    @media(max-width: 1000px){
      display:none;
    }
    @media(max-width: 720px){
      display:none;
    }
    @media(max-width: 500px){
      display:none;
      p{
        font-size: 28px;
        line-height: 33px;
      }
      h1{
        font-size: 28px;
        font-family: 'Montserrat-Regular';
      }
    }
  }
 
  .card-container{
     width: 100%;
    display: block;
    vertical-align: top;
    .card-data{
      width: 47.62%;
      height: auto;
      min-height: 545px;
      box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
      padding: 20px 35px 0px 20px;
      margin:  20px 0px 0px 25px;
      border-radius: 6px;
      display: inline-block;
      vertical-align: top;
      .image-container{
        width: 250px;
        height: 250px;
        margin-left: 25px;
        display: flex;
       -webkit-box-align: center;
        align-items: center;
        >img{
          width:auto;
          height:200px;
        }
      }
      .card-button{
        padding:0px 0px 15px 25px;
        >button{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.025em;
          margin: 0px;
          color: #F6797D;
          background: none;
          border: none;
          >div{
            >span{
              >img{
                width:25px;
                padding-left: 5px;
              }
              div{
                >img{
                width:25px;
                padding-left: 5px;
              }

              }
            }
          }
        }
      }
      .card-title{
        padding: 15px 0px 15px 25px;
        >h3{
          font-family: Rubik-Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.025em;
          color: #24395A;
          margin: 0px;
        }
        >h4{
          font-family: Rubik-Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.025em;
          color: #24395A;
          margin: 0px;
        }
      }
      .card-details{
        padding: 0px 0px 25px 25px;
        min-height: 130px;
        >h3{
          font-family: Montserrat-Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #3A3A3A;
          margin: 0px;
        }
        >p{
          font-family: Montserrat-Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #3A3A3A;
          margin: 0px;
        }
      }
    }
    @media(max-width: 1000px){
      display:none;
    }
    @media(max-width: 720px){
      display:none;
    }
    @media(max-width: 500px){
      display:none;
    }
}
`;
const CustomList = styled(CustomListItem)`
    width: 25%;
    display: flex;
    justify-content: center;
    color: #69c2ff;
    border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '3px solid #EDEDED'};
    >a{
      font-family : 'Rubik-Medium';
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      font-weight: normal;
      color: ${({active})=>active ? '#69c2ff' : "#9C9C9C" };
    }
    &:hover, &:focus, &:active {
      color: #69c2ff;
      border-bottom: 5px solid #69c2ff;
      > a {
        color: #69c2ff;
      }
    }
    @media(max-width: 1200px) {
      border-left: none;
      border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '3px solid #EDEDED'};
      &:hover, &:focus, &:active {
        border-left: none;
        border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '3px solid #EDEDED'};
      }
    }
    ul > li:hover {
      color: #69c2ff;
    }
`;
const TabsContainerV2 = styled(TabsContainer)`
  padding: 0 0px 0 0px;
  @media(max-width: 720px){
    display:none;
  }
`;

const MenuListV2Main2 = styled(MenuList)`
display: flex;
align-items: center;
width: 100%;
justify-content: center;
@media (max-width: 1200px) {
  position: unset;
  width: 100%;
  box-shadow: none;
  background-color: none;
  padding: 0 0 10px;
}
`;

const TabsContainerTestimonial = styled(TabsContainer)`
margin-bottom: 70px;
  @media(max-width: 1000px){
    display:none;
  }
`;

const CoreList = styled.div`
  width:100%;
  padding: 0;
  >div:first-child{
    cursor: pointer;
    display: flex;
    padding: 0px 20px;
    >h1{
      width: 90%;
      color: ${({active})=>active ? '#F6797D' : "#24395A" };
      font-family: ${({active})=>active ? `${ExtraBoldFont}` : `${MediumFont}`};
      margin-left: 15px;
    }
    >h3{
      width: 90%;
      color: ${({active})=>active ? '#F6797D' : "#24395A" };
      font-family: ${({active})=>active ? `${ExtraBoldFont}` : `${MediumFont}`};
      margin-left: 15px;
    }
    >img{
      height: 10%;
      margin: auto 0;
      transform: ${({active}) => active && 'rotateX(180deg)'};
      margin-right: 15px;
    }
  }

  @media(max-width: 1000px){
    .card-container-resp{
      width: 100%;
      display: block;
      .card-data-resp{
      width: 100%;
      height: auto;
      min-height: 500px;
      box-shadow: 0px 0px 11px rgb(0 0 0 / 8%);
      padding: 20px 35px 0px 20px;
      margin: 20px 25px 0px 25px;
      border-radius: 6px;
      display: inline-block;
      vertical-align: top;
      .image-container-resp{
        width: 250px;
        height: 250px;
        margin-left: 25px;
        display: flex;
       -webkit-box-align: center;
        align-items: center;
        >img{
          width: 250px;
          height: 200px;
        }
      }
      .card-title-resp{
        padding: 15px 0px 15px 25px;
        >h3{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.025em;
          color: #24395A;
          margin: 0px;
        }
        >h4{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.025em;
          color: #24395A;
          margin: 0px;
        }
      }
      .card-details-resp{
        padding: 0px 0px 25px 25px;
        min-height: 130px;
        >h3{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          color: #3A3A3A;
          margin: 0px;
        }
        >p{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          color: #3A3A3A;
          margin: 0px;
        }
      }
      .card-button-resp{
        padding:0px 0px 15px 25px;
        >button{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.025em;
          margin: 0px;
          color: #F6797D;
          background: none;
          border: none;
          >div{
            >span{
              >img{
                width:25px;
                padding-left: 5px;
              }
              div{
                >img{
                width:25px;
                padding-left: 5px;
              }

              }
            }
          }
        }
      }
    }
  }
  }
  @media(max-width: 720px){
    .card-container-resp{
      width: 100%;
      display: block;
      .card-data-resp{
      width: 90%;
      height: auto;
      min-height: 500px;
      box-shadow: 0px 0px 11px rgb(0 0 0 / 8%);
      padding: 20px 35px 0px 20px;
      margin: 20px 25px 0px 25px;
      border-radius: 6px;
      display: inline-block;
      vertical-align: top;
      .image-container-resp{
        width: 250px;
        height: 250px;
        margin-left: 25px;
        display: flex;
       -webkit-box-align: center;
        align-items: center;
        >img{
          width: 250px;
          height: 200px;
        }
      }
      .card-title-resp{
        padding: 15px 0px 15px 25px;
        min-height: 130px;
        >h3{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.025em;
          color: #24395A;
          margin: 0px;
        }
        >h4{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.025em;
          color: #24395A;
          margin: 0px;
        }
      }
      .card-details-resp{
        padding: 0px 0px 25px 25px;
        min-height: 130px;
        >h3{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          color: #3A3A3A;
          margin: 0px;
        }
        >p{
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          color: #3A3A3A;
          margin: 0px;
        }
      }
      .card-button-resp{
        padding:0px 0px 15px 25px;
        >button{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.025em;
          margin: 0px;
          color: #F6797D;
          background: none;
          border: none;
          >div{
            >span{
              >img{
                width:25px;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  }
  @media(max-width: 500px){
    .card-container-resp{
    width: 100%;
    display: block;
    .card-data-resp{
    width: 87%;
    height: auto;
    min-height: 500px;
    box-shadow: 0px 0px 11px rgb(0 0 0 / 8%);
    padding: 20px 35px 0px 20px;
    margin: 20px 25px 0px 25px;
    border-radius: 6px;
    display: inline-block;
    vertical-align: top;
    .image-container-resp{
      width: 250px;
      height: 250px;
      margin-left: 25px;
      display: flex;
     -webkit-box-align: center;
      align-items: center;
      margin:0px;
      >img{
        width: 250px;
        height: 200px;
      }
    }
    .card-title-resp{
      padding: 15px 0px 15px 25px;
      >h3{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.025em;
        color: #24395A;
        margin: 0px;
      }
      >h4{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.025em;
        color: #24395A;
        margin: 0px;
      }
     }
    .card-details-resp{
      padding: 0px 0px 25px 25px;
      min-height: 130px;
      >h3{
        font-family: Montserrat-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #3A3A3A;
        margin: 0px;
      }
      >p{
        font-family: Montserrat-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #3A3A3A;
        margin: 0px;
      }
    }
    .card-button-resp{
      padding:0px 0px 15px 25px;
      >button{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.025em;
        margin: 0px;
        color: #F6797D;
        background: none;
        border: none;
        >div{
          >span{
            >img{
              width:25px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  }
  }



  @media(max-width: 435px){
    .card-container-resp{
    .card-data-resp{
      text-align: center;
      padding: 20px 31px 0px 38px;
    .card-title-resp{
      min-height:0px
        padding: 66px 0px 19px 1px;
      >h3{
        font-size: 18px;
        line-height: 22.33px;
      }
      >h4{
        font-size: 18px;
        line-height: 22.33px;
      }
     }
     .card-details-resp {
     padding: 0px 0px 25px 2px;
     }
  }
  }
  }
  
  @media(max-width: 360px){
    .card-container-resp{
    width: 94%;
    display: block;
    .card-data-resp{
    width: 100%;
    height: auto;
    min-height: 500px;
    box-shadow: 0px 0px 11px rgb(0 0 0 / 8%);
    padding: 20px 25px 0px 25px;
    margin: 0px 10px 0px 10px;
    border-radius: 6px;
    display: inline-block;
    vertical-align: top;
    .image-container-resp{
      width: 250px;
      height: 250px;
      display: flex;
     -webkit-box-align: center;
      align-items: center;
      margin: 0px;
      >img{
        width: 250px;
        height: 200px;
      }
    }
    .card-title-resp{
      padding: 15px 0px 15px 25px;
      >h3{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.025em;
        color: #24395A;
        margin: 0px;
      }
      >h4{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.025em;
        color: #24395A;
        margin: 0px;
      }
     }
    .card-details-resp{
      padding: 0px 0px 25px 25px;
      min-height: 130px;
      >h3{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #3A3A3A;
        margin: 0px;
      }
      >p{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #3A3A3A;
        margin: 0px;
      }
    }
    .card-button-resp{
      padding:0px 0px 15px 25px;
      >button{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.025em;
        margin: 0px;
        color: #F6797D;
        background: none;
        border: none;
        >div{
          >span{
            >img{
              width:25px;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  }
  } 
`;
const ShowsImg= styled.div`
width:100%;
display:flex;
float: left;
padding: 40px 0;
margin: 20px 20px;
justify-content: center;
text-align: center;
flex-wrap: wrap;
>div:first-child{
  display: flex;
  align-items: center;
  justify-content: center;
  width:20%;
  >img{
height: 50px;
width: auto;
  }
}
>div:nth-child(2){
  display: flex;
  align-items: center;
  justify-content: center;
  width:20%;
  >img{
    
height: 50px;
width: auto;
  }
}
>div:nth-child(3){
  display: flex;
  align-items: center;
  justify-content: center;
  width:20%;
  >img{
height: 43px;    
width: auto;
  }
}
>div:nth-child(4){
  display: flex;
  align-items: center;
  justify-content: center;
  width:20%;
  >img{
height: 50px;
width: auto;
  }
}
>div:nth-child(5){
  display: flex;
  align-items: center;
  justify-content: center;
  width:20%;
  >img{   
height: 50px;
width: auto;
  }
}
@media(max-width: 1200px){
  padding: 0 10px;
  >div{
    width: auto;
    margin: 20px;
  }
}
@media (max-width: 700px){
  justify-content: space-between;
  >div:first-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    >img{
      width: 125px;
      height: 36px;
    }
  }
  >div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    >img{
      width: 116px;
      height: 58px;
    }
  }
  >div:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    >img{
      width: 125px;
      height: 59px;
    }
  }
  >div:nth-child(4){
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    >img{
      width: 119px;
      height: 49px;
    }
  }
  >div:nth-child(5){
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    >img{
      width: 193px;
      height: 48px;
    }
  }
`;
const  ButtonLearnMoree = styled.button`
width: ${(props) => props.tryFree ? '190px' : '190px'};
height: 60px;
background-color: ${(props) => props.tryFree ? '#fff' : 'rgb(159, 201, 137)' };
color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
display: ${({display}) => display}; 
padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
border-radius: 3px;
border: none;
font-size: 18px;
line-height: 24px;
font-family: Rubik-Medium;
font-style: normal;
box-shadow: 4px 6px 5px -1px rgba(0, 0, 0, 0.11);
cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};

&:hover, &:focus, &:active {
  outline: none;
}

@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
}

@media (max-width: 720px) {
  width: 80%;
  width: 320px;
  height: 50px;
}

@media (min-width: 1420px) {
  font-size: 18px;
  padding: 15px 10px;
}
@media (min-width: 1201px) {
  width: 36%;
  width: 190px;
  font-size: 18px;
  padding: 10px 10px;
}
`;
const ResourcesV2 = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top:30px;
  flex-direction:${({flexDirection}) => flexDirection};
  justify-content: space-between;
  margin-bottom: 150px;
  >div:first-child{
    .responsive{
      display:none;
    }
    .responsive-image{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    >div:first-child{
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 35px;
      flex-direction: row;
      @media (max-width: 500px) {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .title{
        font-size: 30px;
        color: ${({col})=>col};
        font-family: Rubik-Medium;
        font-style: normal;
        line-height: 58px;
        letter-spacing: 0;
        display:inline;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        @media (max-width: 700px){
          font-size: 24px;
        }
      } 
      >img{
        width: 60px;
        height: 60px;
        @media (max-width: 700px){
          margin-left: 20px;
        }
      }
    }
    >div{
      width: 100%;
      display: flex;
      flex-direction: column;
      cursor:pointer;
    }
    >div:nth-child(3){
      >div{
        >div:last-child{
          >img{
            width:100%;
            margin-top: 25px;
            @media(max-width: 700px){
              width: 350px;
            }
          }
        }
      }
    }
  }
  > div:last-child {
    width: 100%;
    align-items:${({align}) => align};
    img {
        float: left;
        width: auto;
        height: 400px;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
          //  width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width:600px){
    padding:0;
    margin-top: 10px;
    margin-bottom: 25px;
    >div:first-child{
      padding: 0;
      .normal{
        display:none;
      }
      .responsive{
        display:block;
      }
    }
    >div:last-div{
      display:none;
    }
    .image{
      display:none;
    }
    >div:first-child{
      >div:last-child{
        >div{
          >div{
            .responsive-image{
                display: flex;
                align-items: center;
                justify-content: center;
              >img{
                width:100%;
              }
            }
          }
        }
      }
    }
  }
`;
const RowWrapperV2 = styled.div`
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected,bgColor}) => selected ? bgColor : '#fff'};
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 10px;
  .header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  }
  >div{
  width: calc(100% - 70px);
  font-size: 22px;
  font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`} ;
  color: #24395A;
  }
  .downArrow{
  width: 20px;
  transform: ${({selected}) => selected && 'rotateX(180deg)'};
  >img{
  width: 14px;
  cursor: pointer;
  }
  }
  }
  .expand{
  padding: 20px 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  font-family: ${MontserratRegular};;
  font-style: normal;
  color: #3A3A3A;
  cursor: pointer;
  }
  @media(max-width:500px){
  display:none;
  }
`;
const CoreFeatureSection = styled.div`
  width: 100%;
  display: flex;
  //padding-top: 225px;
  padding-bottom:5px;
  >div{
      width:100%;
      justify-content:center;
      text-align: center;
    >p{
      color:#F6797D;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      font-family: ${MontserratSemiBold};
    }
    >h1{
      font-size: 36px;
      line-height: 120.5%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #24395A;
      text-transform: capitalize;
      font-family: Rubik-Medium;
    }
    >h2{
      font-size: 36px;
      line-height: 120.5%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #24395A;
      text-transform: capitalize;
      font-family: Rubik-Medium;
    }
    >img{
      margin-top: 30px;
    }
  }
  @media(max-width: 600px){
    padding-top: 0;
    >div{
      text-align: center;
      >p{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      >h1{
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
      }
      >h2{
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
      }
    }
  }
  @media(max-width: 500px){
    padding: 0px 20px;
    >div{
      >h1{
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
      }
      >h2{
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
      }
    }
  }
`;
const RowResponsiveWrapperV2 = styled.div`
  display: block;
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected}) => selected ? 'rgba(157,135,169,0.1)' : '#fff'};
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >img{
      width: 30px;
      height: 30px;
      margin-right: 10px;
      cursor: pointer;
    }
    >div{
      width: calc(100% - 70px);
      font-size: 24px;
      font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`};
      color: #24385A;
    }
    .downArrow{
      width: 20px;
      transform: ${({selected}) => selected && 'rotateX(180deg)'};
      >img{
        width: 14px;
        cursor: pointer;
      }
    }
  }
  .expand{
    padding: 20px 30px 0 50px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    font-family: ${MontserratRegular};
    cursor: pointer;
    @media(max-width: 700px){
      padding: 20px 0px 0 0px;
    }
    @media(max-width: 435px){
      font-size:15px;
      line-height: 21px;
    }
  }
  @media(max-width: 500px){
    display: flex;
    .header{
      >div{
      font-size: 22px;
      }
    }
  }
`;


const CoreWrapperV2 = styled.div`
  width: 100%;
  margin: 100px 0;
  margin: auto;
  .change {
    padding-left: 235px;
  }
  .tab-header{
    text-align:center;
    margin-bottom: 80px;
    max-width: 1200px;
    margin: auto;
    margin-bottom: 80px;
    >p{
      font-family: 'Montserrat-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #24395A;
      @media(max-width: 720px){
        padding: 0px 6px;
        font-size: 16px
      }
      margin-bottom: 30px;
      @media(max-width: 500px){
        padding: 0px 8px;
      }
    }
    >h1{
      font-family: ${ExtraBoldFont};
      font-size: 36px;
      line-height: 120.5%;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
    >h2{
      font-family: ${ExtraBoldFont};
      font-size: 36px;
      line-height: 120.5%;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
    @media(max-width: 435px){
      h1{
        font-size: 28px;
        line-height: 33px;
      }
    }
  }
 
  .card-container{
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
    .card-data{
      width: 315px;
      height: 315px;
      box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.08);
      margin:  15px 0px 15px 30px;
      border-radius: 6px;
      @media(max-width: 500px){
        width: 100%;
        margin:  15px 30px 15px 30px;
      }
      @media(max-width: 720px){
        width: 100%;
        margin:  15px 30px 15px 30px;
      }
      @media(max-width: 1000px){
        width: 100%;
        margin:  15px 30px 15px 30px;
      }
      .image-container{
        width: auto;
        height: 100px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom:25px;
        margin-top:25px;
        >img{
          width: 100px;
          height: 100px;
          height:auto;
          max-height: 250px;
        }
      }
      .card-title{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 16px;
        >h3{
          font-family: 'Rubik-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.025em;
          color: #24395A;
          margin: 0px;
        }
       
      }
      .card-details{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 25px 27px 25px;
        text-align: center;
        >h3{
          font-family: 'Montserrat-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #9c9c9c;
          margin: 0px;
        }
       
      }

    }
  }
  .card-button{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    margin-top:35px;
    >button{
      height: 50px;
      width: 250px;
      white-space: nowrap;
      border: none;
      -webkit-letter-spacing: 1px;
      -moz-letter-spacing: 1px;
      -ms-letter-spacing: 1px;
      letter-spacing: 1px;
      display: inline-block;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #FD7175;
      cursor: pointer;
      background-color: rgba(253, 113, 117, 0.1);
      border-radius: 6px;
      &:hover{
        background-color: #FD7175;
        color: #FFFFFF;
      }
    }   
        }
`;


export{TopPanel, Button, TopPanelContent,TextImagesContainer, RowResponsiveWrapper, MiddlePanel, MiddlePanelSecond, ShowsImg,
  CoreFeatureSection,CoreFeatureSectionV2,RowResponsiveWrapperV2, Resources,ResourcesV2,RowWrapperV2, MainResources, WellBeing, Button2, MenuListV2,AwarenessSection, TabData, TabsContainer, CustomListItemV2, FooterSection, WrapperContainer, RatingsContainer, ResourcesWrapper, RowWrapper, ButtonGetStarted, ShowLogos, ButtonLearnMore, OverFooter, Divider, ShowLogosDivider, PaddingContainer, RatingWrapper, Wrapper, ResponsiveTestimonialTab, RatingsContainers, TabsContainerTestimonial, MenuListV2Main, CustomListItemV2Main, CoreWrapperNew,CustomList,TabsContainerV2, MenuListV2Main2, CoreList,ButtonLearnMoree, CoreWrapperV2};
